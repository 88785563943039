// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_CRSPw[data-v-5fe51c36]{padding:48px 0 0 0;text-align:left;width:100%}@media (min-width:768px){.container_CRSPw[data-v-5fe51c36]{padding:60px 18px 30px}}.page-message_YGS4m[data-v-5fe51c36]{margin:0 0 48px 0}.page-message_YGS4m p[data-v-5fe51c36]{margin:0}.header_sG1TD+.page-message_YGS4m[data-v-5fe51c36]{margin-top:-24px}@media (min-width:768px){.page-message_YGS4m[data-v-5fe51c36]{margin:0 0 60px 0}.header_sG1TD+.page-message_YGS4m[data-v-5fe51c36]{margin-top:-36px}}.section_m9_lM+.section_m9_lM[data-v-5fe51c36]{margin-top:60px}.section__header_C8CJ5[data-v-5fe51c36]{margin:0 0 48px;font-family:Gerstner-Programm,Helvetica,Segoe UI,sans-serif;font-style:normal;font-weight:700;font-size:16px;line-height:1;color:#3f3f40;border-bottom:1px solid #ceced0;padding:0 0 11px 0}.page-controls_Jkw4U[data-v-5fe51c36]{margin-top:120px;text-align:center}.incorporation-message_zmZUd[data-v-5fe51c36]{display:flex;align-items:center;margin:0}.incorporation-message__icon_PPT5o[data-v-5fe51c36]{margin-right:16px}.incorporation-message_zmZUd+.incorporation-button_a30JR[data-v-5fe51c36]{margin-top:48px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_CRSPw",
	"page-message": "page-message_YGS4m",
	"header": "header_sG1TD",
	"section": "section_m9_lM",
	"section__header": "section__header_C8CJ5",
	"page-controls": "page-controls_Jkw4U",
	"incorporation-message": "incorporation-message_zmZUd",
	"incorporation-message__icon": "incorporation-message__icon_PPT5o",
	"incorporation-button": "incorporation-button_a30JR"
};
export default ___CSS_LOADER_EXPORT___;
