// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body .footer_mmGkk button.footer__button_tH7mP{margin:0;width:calc(50% - 8px)}body .footer_mmGkk button.footer__button_tH7mP+button.footer__button_tH7mP{margin-left:16px}.modal-content_SD1cx p{margin:0}.accounts_br7WI{margin-top:30px;max-width:665px}.accounts__item_GsCij{display:inline-flex;align-items:center;border:1px solid #e8e8ea;padding:16px;width:100%;max-width:344px}.accounts__item_GsCij+.accounts__item_GsCij{margin-top:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer_mmGkk",
	"footer__button": "footer__button_tH7mP",
	"modal-content": "modal-content_SD1cx",
	"accounts": "accounts_br7WI",
	"accounts__item": "accounts__item_GsCij"
};
export default ___CSS_LOADER_EXPORT___;
