/* eslint-disable max-len */

// ctx = "context" - includes state, dispatch, getters, etc

import analytics from '@/utils/analytics';
import { makeAsyncAction } from '@/utils/vuex-api-utils';
import Cookies from 'js-cookie';
import storage from 'local-storage-fallback';
import * as types from './mutation-types';

export async function fetchUserData(store, userId) {
  const user = await store.dispatchApiAction('GET_USER', { userId });
  // TODO (sc-178331) Remove this condition when the write user in BAUS is done
  if (!user.business && user.businessId) {
    await store.dispatchApiAction('GET_BUSINESS');
    await store.dispatchApiAction('GET_OWNERS');
  }
  await store.dispatchApiAction('FETCH_USER_UPLOADS');
  await store.dispatch('UPDATE_ALL_PAYMENTS_ONBOARDING_STEPS');
}

export function setSignupParams(ctx, payload) {
  // payload can contain props that are pre-filled in signup popup
  // see signup-page.vue for details
  ctx.commit(types.SET_SIGNUP_PARAMS, payload);
}

export const oauthConnect = makeAsyncAction(
  types.OAUTH_CONNECT,
  (ctx, payload) => ({
    method: 'post',
    url: `/oauth/${payload.provider}`,
    params: {
      ...payload,
      language: ctx.rootGetters.selectedLanguage,
    },
    options: { timeout: 45000 }, // makes requests to external services
    async afterSuccess(response) {
      // this was a login
      if (response.authToken) {
        Cookies.set('cb-user-token', response.authToken, {
          sameSite: 'None',
          secure: true,
        });
        storage.setItem('cb-user-id', response.user.id);
        analytics.identify(ctx.rootGetters.userId);
        analytics.track('fe_login');
        await ctx.commit('SET_CURRENT_USER', {
          ...response.user,
          oauthSignup: response.signup,
        });
        // TODO (SC-178331): remove condition when we rollout isBausIntegrationEnabled
        if (!response.user.business && response.user.businessId) {
          await ctx.dispatchApiAction('GET_BUSINESS');
          await ctx.dispatchApiAction('GET_OWNERS');
        }
        await ctx.dispatchApiAction('GET_USER_LANGUAGE');
      }
      // this was a new signup - well let the oauth signup page deal with signup tracking
      // if (response.signup) {}

      // use existing mutation to add the account to the store
      // even if it is actually an update, using the "ADD" mutation is safe
      // because it just calls Vue.set internally
      ctx.commit('api-ADD_USER_EXTERNAL_ACCOUNT', {
        response: response.externalAccount,
      });
    },
  }),
);

// ADVANCES /////////////
export const createAdvance = makeAsyncAction(
  types.CREATE_ADVANCE,
  (ctx, payload) => ({
    method: 'post',
    url: `/users/${ctx.getters.userId}/advances`,
    params: payload,
  }),
);
export const refreshAdvances = makeAsyncAction(
  types.GET_USER_ADVANCES,
  (ctx) => ({
    method: 'get',
    url: `/users/${ctx.rootGetters.userId}/advances`,
  }),
);

export const selectAdvanceOffer = makeAsyncAction(
  types.SELECT_ADVANCE_OFFER,
  (ctx, payload) => ({
    method: 'post',
    url: `/advances/${payload.advanceId}/offers/${payload.offerId}/select`,
  }),
);

export const createBNPLAdvance = makeAsyncAction(
  types.CREATE_BNPL_ADVANCE,
  (ctx, payload) => ({
    method: 'post',
    url: `/users/${ctx.rootGetters.userId}/advances`,
    params: payload,
    returnResponse: true,
  }),
);
export const requestClearCardContractSign = makeAsyncAction(
  types.SIGN_CLEARCARD_CONTRACT,
  (ctx, payload) => ({
    method: 'post',
    url: `/credits/${payload.creditId}/sign`,
    options: { timeout: 15000 }, // makes requests to HelloSign so can be slow
  }),
);
export const refreshContractStatus = makeAsyncAction(
  types.REFRESH_ADVANCE_SIGNATURE_STATUS,
  (ctx, payload) => ({
    method: 'patch',
    url: `/contracts/refresh-contract-details`,
    params: payload,
    options: { timeout: 15000 }, // makes requests to HelloSign so can be slow
  }),
);

// ECOM FINANCIAL STATS /////////////
export const getEcomRevenue = makeAsyncAction(
  types.GET_ECOM_REVENUE,
  (ctx, payload) => ({
    method: 'get',
    url: `/businesses/${ctx.rootGetters.businessId}/ecom-revenue`,
  }),
);

export const fetchFacebookMetadata = makeAsyncAction(
  types.FETCH_FACEBOOK_AD_ACCOUNT_INFO,
  (ctx, payload) => ({
    method: 'get',
    url: `/facebook-ad-account/${ctx.rootGetters.businessId}`,
  }),
);

export const setAutocomplete = async function (ctx, payload) {
  ctx.commit(types.SET_AUTOCOMPLETE, payload);
};
export const globalAutocomplete = async function (ctx, payload) {
  ctx.commit(types.GLOBAL_AUTOCOMPLETE_LOADED, payload);
};

export const setCurrentStateModule = function (ctx, stateModule) {
  ctx.commit(types.SET_CURRENT_STATE_MODULE, stateModule);
};
