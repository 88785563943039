<template>
  <div
    :tabindex="clickable ? 0 : -1"
    :class="[
      $style.bank,
      disabled && $style['bank--disabled'],
      clickable && $style['bank--clickable'],
      selected && $style['bank--selected'],
    ]"
  >
    <div v-if="tag" :class="$style.bank__tag">{{ tag }}</div>
    <div v-if="clickable" :class="$style.bank__bullet"></div>
    <BankAccountLogo
      :class="$style.bank__logo"
      :image="image"
      :bank-name="bankName"
    />
    <div>
      <div :class="$style['bank__details__account-name']">
        {{ accountName }}
      </div>
      <div :class="$style['bank__details__bank-name']">{{ bankName }}</div>
      •
      <div :class="$style['bank__details__account-number']">
        *** *** {{ accountNumber }}
      </div>
    </div>
  </div>
</template>

<script>
import BankAccountLogo from '@/components/BankAccountLogo';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
    },
    image: {
      type: String,
    },
    bankName: {
      type: String,
      required: true,
    },
    accountName: {
      type: String,
      required: true,
    },
    accountNumber: {
      type: String,
      required: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BankAccountLogo,
  },
};
</script>

<style lang="less" module>
.bank {
  position: relative;
  border: 1px solid #5b00f0;
  text-align: left;
  padding: 16px;
  display: flex;
  align-items: center;
  color: #303032;
  font-size: 14px;
  line-height: 1.4;
}

.bank--disabled {
  border-color: #e8e8ea;
}

.bank--clickable {
  cursor: pointer;
  border: 1px solid #e8e8ea;
}

.bank--selected {
  border-color: #5b00f0;
}

.bank__tag {
  position: absolute;
  top: -16px;
  left: 4px;
  padding: 6px 8px;
  background: #000;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}

.bank__bullet {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  margin-right: 16px;
}

.bank--selected .bank__bullet {
  border-width: 4px;
  border-color: #5b00f0;
}

.bank__logo {
  margin-right: 8px;
}

.bank__details__account-name {
  font-weight: bold;
}

.bank__details__bank-name {
  display: inline-block;
  margin-right: 2px;
}

.bank__details__account-number {
  display: inline-block;
  margin-left: 2px;
}
</style>
