<template>
  <Modal
    v-model:visible="showModal"
    header="Make it primary?"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <div :class="$style['modal-content']">
      <p :class="$style.description">
        All future debits will withdraw from this primary account. You will be
        required to sign a new ACH for payment authorization.
      </p>
      <ul :class="$style.banks">
        <li
          v-if="currentPrimary"
          :class="[$style.banks__item, $style['banks__item--disabled']]"
        >
          <BankAccountItem
            :disabled="true"
            tag="Current"
            :image="plaidInstitutions[currentPrimary.bankName]?.logo"
            :bank-name="currentPrimary.bankName"
            :account-name="currentPrimary.label"
            :account-number="currentPrimary.accountNumberLast4"
          />
        </li>

        <li v-if="selectedAccount" :class="$style.banks__item">
          <BankAccountItem
            tag="New"
            :image="plaidInstitutions[selectedAccount.bankName]?.logo"
            :bank-name="selectedAccount.bankName"
            :account-name="selectedAccount.label"
            :account-number="selectedAccount.accountNumberLast4"
          />
        </li>
      </ul>
    </div>

    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="Cancel"
          class="p-button-outlined p-button-secondary"
          :class="$style.footer__button"
          @click="closeModal"
        />
        <DSButton
          label="Confirm"
          :class="$style.footer__button"
          @click="confirmPrimaryAccountChange"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import BankAccountItem from '@/components/BankAccountItem';

export default {
  emits: ['close'],
  components: {
    Modal,
    DSButton,
    BankAccountItem,
  },
  props: {
    selectedAccount: {
      type: Object,
    },
    bankAccountIdToBePrimary: {
      required: true,
      validator: (prop) => typeof prop === 'number' || prop === null,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['bankAccounts', 'plaidInstitutions']),
    currentPrimary() {
      return this.getBankAccountDataById(this.bankAccountIdToBePrimary);
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async confirmPrimaryAccountChange() {
      this.$emit('close');
      this.closeModal();
    },
    getBankAccountDataById(id) {
      return this.bankAccounts.find((account) => account.id === id);
    },
  },
};
</script>

<style lang="less" module>
body [class^='p-dialog'] [class^='p-dialog-content'] {
  padding: 0;
  margin: 2rem;
}

.description {
  max-width: 500px;
  margin: 0;
}

.banks {
  margin-top: 30px;
}

.banks__item + .banks__item {
  margin-top: 24px;
}

.banks__item--disabled {
  opacity: 0.5;
}

body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

body .footer button.footer__button + button.footer__button {
  margin-left: 16px;
}
</style>
