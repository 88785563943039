<template>
  <LayoutConnectAccounts :show-encryption-message="false">
    <template #titles>
      <h2 :class="$style.heading">Financial Documents</h2>
      <p :class="$style['sub-heading']">
        Please enter your fiscal year start date and upload the following
        financial documents: balance sheets and income/P&L statements of the
        <strong>most recent</strong> complete quarter and fiscal year.
      </p>
    </template>
    <div :class="$style['calendar-wrapper']">
      <CalendarGroup
        v-model="fiscalStartDate"
        label="Fiscal Year Start Date"
        name="calendar"
        :manual-input="false"
        show-icon
        @date-select="fiscalDateChange"
      />
    </div>
    <FormSubmitFinancialStatements
      :class="$style.upload"
      :event-tracking-fields="commonTrackingFields"
      @fileUploaded="handleFileUploaded"
    />
    <div
      class="c-form"
      :style="{
        textAlign: 'center',
      }"
    >
      <div class="c-form__controls justify-center" :style="{ margin: '0' }">
        <DSButton
          class="p-button-outlined"
          :label="$t('common.buttons.back')"
          @click="
            $router.push({
              name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
            })
          "
        />
        <DSButton
          :disabled="isNextDisabled"
          :label="$t('common.buttons.next')"
          @click="done"
        />
      </div>
      <DSButton
        :label="$t('common.buttons.skipForNow')"
        class="p-button-text p-button-info"
        severity="info"
        :style="{ marginTop: '1rem' }"
        @click="done"
      />
    </div>
  </LayoutConnectAccounts>
</template>

<script>
import { mapGetters } from 'vuex';
import DSButton from '@clearbanc/clear-components/button';
import {
  LayoutConnectAccounts,
  FormSubmitFinancialStatements,
} from '@/components';
import addLocaleParam from '@/utils/add-locale-param';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import CalendarGroup from '@clearbanc/clear-components/calendargroup';

export default {
  components: {
    DSButton,
    LayoutConnectAccounts,
    FormSubmitFinancialStatements,
    CalendarGroup,
  },
  data() {
    return {
      PAYMENTS_ROUTE_NAMES,
      uploadData: null,
      fiscalStartDate: null,
      apiError: false,
    };
  },
  async mounted() {
    await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');

    this.fiscalStartDate = this.business.fiscalStartDate
      ? this.formatDate(this.business.fiscalStartDate)
      : null;
  },
  computed: {
    ...mapGetters(['business', 'fiscalDocumentChecks']),
    isNextDisabled() {
      if (!this.fiscalStartDate) {
        return true;
      }

      if (
        !this.fiscalDocumentChecks.lastQuartersStatementUploaded &&
        !this.uploadData
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    addLocaleParam,
    async updateFiscalStartDate() {
      await this.$store.dispatchApiAction('UPDATE_BUSINESS', {
        fiscalStartDate: this.fiscalStartDate,
      });
    },
    async done() {
      await this.$store.dispatchApiAction('GET_FISCAL_DOCUMENT_CHECKS');
      this.$router.push({
        name: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS,
      });
    },
    handleFileUploaded(uploadData) {
      this.uploadData = uploadData;
    },
    formatDate(date) {
      const dateObject = new Date(date);
      return dateObject.toISOString().split('T')[0];
    },
    async fiscalDateChange(newDate) {
      await this.$store.dispatchApiAction('UPDATE_BUSINESS', {
        fiscalStartDate: new Date(newDate).toISOString(),
      });
    },
  },
};
</script>

<style>
.justify-center {
  justify-content: center !important;
}
</style>

<style lang="less" module>
h2.heading {
  font-size: 21px;
  line-height: 30px;
  font-weight: 500;
  margin: 0 0 24px;
}

.sub-heading {
  margin: 0 auto 2rem;
  max-width: 550px;
}

@media screen and (max-width: 500px) {
  h2.heading,
  .sub-heading {
    text-align: left;
  }
}

.calendar-wrapper {
  max-width: 350px;
  margin: 50px auto 30px;
}

.upload {
  margin: 0 auto 60px;
}
</style>
