/* eslint-disable object-property-newline */

import { createRouter, createWebHistory } from 'vue-router';
import { authGuard } from '@auth0/auth0-vue';
import analytics from '@/utils/analytics';
import { auth0 } from '@/utils/auth0';
import {
  PAYMENTS_ROUTE_NAMES,
  PAYMENTS_TRACKING_PARAMS,
} from '@/data/payments';
import store from '@/store';
import { fetchUserData } from '@/store/actions';
import { ENVIRONMENTS } from '@/data/environments';
import { bausRequest } from '@/utils/baus-request';

// MCA Pages
import FinancingHomePage from './pages/mca/financing';
import SpendPage from './pages/mca/financing/spend';
import AdvancesPage from './pages/mca/financing/advances';
import SpendTransactionsPage from './pages/mca/spend-transactions-page';
import PaymentsPage from './pages/mca/payments-page';
import ApplicationComponent from './pages/mca/Application';
import ClearbancCardSubpage from './pages/mca/subpage-clearbanc-card';
import VendorListPage from './pages/mca/vendor-list';

// General Pages
import NotFoundPage from './pages/page-not-found';
import Maintenance from './pages/maintenance';

// Invoice Funding Dashboard Pages
import HowItWorks from './pages/how-it-works';

// Auth0 Relates Pages
import HomePage from './pages/PageHome';
import PasswordResetPage from './pages/password-reset';
import EmbedPage from './pages/embed-page';
import Calculator from './components/how-it-works/Calculator';
import EmailVerificationPage from './pages/email-verification-page';

// External Accounts OAuth
import OauthCallbackPage from './pages/page-oauth-callback';
import CodatCallbackPage from './pages/page-codat-callback';
import PlaidOauthRedirect from './pages/oauth-plaid-redirect';

// Shared Product Pages
import VendorsPage from './pages/account/vendors/page-vendors';
import VendorPaymentsSubPage from './pages/account/vendors/subpage-payment-transactions';
import VendorRepaymentsSubPage from './pages/account/vendors/subpage-repayments';

// My Account
import AccountPage from './pages/account/page-my-account';
import SettingsSubpage from './pages/account/subpage-settings';
import DataSecurityPage from './pages/account/data-security/page-data-security';
import ExportDataPage from './pages/account/data-security/page-export-data';
import RemoveDataPage from './pages/account/data-security/page-remove-data';

// Onboarding Pages
import PaymentsOnboardingFlow from './pages/payments';
import PaymentsUserProfilePage from './pages/payments/profile-details';
import PaymentsBusinessDetailsPage from './pages/payments/business-details';
import PaymentsRegisteredAddressPage from './pages/payments/registered-address';

// Business Profile
import PaymentsBizProfileDashboard from './pages/payments/business-profile';
import PaymentBizProfileConnectAccounts from './pages/payments/business-profile/connect-accounts';
import PaymentBizProfileConnectMarketing from './pages/payments/business-profile/connect-marketing';
import PaymentBizProfileConnectBanking from './pages/payments/business-profile/connect-banking';
import PaymentBizProfileBankDocuments from './pages/payments/business-profile/bank-documents';
import PaymentBizProfileBankStatements from './pages/payments/business-profile/BankStatements';
import PaymentBizProfileDebtObligations from './pages/payments/business-profile/DebtObligations';
import PaymentBizProfileBusinessDetails from './pages/payments/business-profile/business-details';
import PaymentBizProfileBusinessDetailsPersona from './pages/payments/business-profile/business-details-persona';
import PaymentBizProfileBusinessOwners from './pages/payments/business-profile/business-owners';
import PersonaBizProfilePersonaBusinessOwners from './pages/payments/business-profile/persona/business-owners';
import PaymentBizProfileBusinessRegisteredAddress from './pages/payments/business-profile/registered-address';
import PaymentsProfileBusinessIncoroporationDocs from './pages/payments/business-profile/business-incorporation-docs';
import BusinessProfileOwnersStepOne from './pages/payments/business-profile/owners/step-one';
import BusinessProfileOwnersStepTwo from './pages/payments/business-profile/owners/step-two';
import BusinessProfileOwnersList from './pages/payments/business-profile/owners/list';
import PaymentsAllDocuments from './pages/payments/all-documents';

// Callback page for auth0
import LoginAuth0Page from './pages/PageLoginAuth0';
import Auth0ErrorPage from './pages/PageAuth0Error';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (to.path.indexOf('/case-studies/') > -1 && window.scrollY > 0)
      return savedPosition || { left: 0, top: window.scrollY };
    return savedPosition || { left: 0, top: 0 };
  },
  routes: [
    // This is to be sure that the user is redirected with the required params in order to show the "signup" tab on the auth0 component
    {
      path: '/signup',
      beforeEnter: (to) => {
        if (
          Object.keys(to.query).some((param) =>
            PAYMENTS_TRACKING_PARAMS.includes(param),
          )
        ) {
          localStorage.setItem('signupParams', JSON.stringify(to.query));
        }

        const { location } = window;

        location.href = `${
          location.origin
        }/?initialScreen=signUp&${location.search.replace('?', '&')}`;
      },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
    },
    {
      path: '/plaid-oauth-callback',
      name: 'plaid-oauth-callback',
      component: PlaidOauthRedirect,
    },
    {
      path: '/email-verification',
      name: 'email-verification',
      component: EmailVerificationPage,
    },
    {
      path: '/embed',
      name: '/embed',
      component: EmbedPage,
      meta: {
        displayNav: false,
      },
      children: [
        {
          path: 'calculator',
          name: 'calculator',
          component: Calculator,
        },
      ],
    },
    {
      path: '/',
      name: 'home',
      component: HomePage,
      beforeEnter: async (to, _) => {
        if (to.query.error) {
          return { name: 'auth-error', query: to.query };
        }
        if (store.getters.isMaintenancePageEnabled) {
          return { name: 'maintenance' };
        }
        if (auth0.isAuthenticated.value) {
          await auth0.getAccessTokenSilently();
          const { userId } = auth0?.user?.value ?? {};
          if (store.getters.isEmailVerificationEnabled && !userId) {
            return { name: 'email-verification', query: to.query };
          }
        }
        return authGuard(to);
      },
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordResetPage,
    },
    {
      path: '/login',
      name: 'login',
      component: LoginAuth0Page,
      beforeEnter: (to, from) => {
        if (to.query.error) {
          return { name: 'auth-error', query: to.query };
        }
        return authGuard(to);
      },
    },
    {
      path: '/error',
      name: 'auth-error',
      component: Auth0ErrorPage,
      props: (route) => ({
        errorMessage: route.query && route.query.error_description,
      }),
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: async (to, from) => {
        store.commit('USER_LOGOUT');
        store.commit('RESET_SIGNUP_STATE');
        analytics.track('fe_logout');
        await auth0.logout({
          returnTo: window.location.origin,
        });
      },
    },
    {
      path: '/spend-transactions', // MCA Route
      name: 'spend-transactions',
      component: SpendTransactionsPage,
      beforeEnter: authGuard,
      meta: {
        requiresAuth: true,
        segment: [
          'core',
          'self_serve',
          'extend_runway',
          'mrr_factoring',
          'indiegogo',
          'bnpl',
        ],
        displayNav: true,
      },
    },
    {
      path: '/payments', // MCA Route
      name: 'payments',
      component: PaymentsPage,
      beforeEnter: authGuard,
      meta: {
        requiresAuth: true,
        segment: [
          'core',
          'self_serve',
          'extend_runway',
          'mrr_factoring',
          'bnpl',
        ],
        displayNav: true,
      },
    },
    // oauth redirects to this page > calls parent window js + closes
    // Used to connect external accounts
    {
      path: '/oauth-callback',
      name: 'oauth-callback',
      component: OauthCallbackPage,
      beforeEnter: authGuard,
    },
    {
      path: '/codat/:companyId/:platform',
      name: 'Codat Complete',
      component: CodatCallbackPage,
    },
    /**
     * WAYFINDING ROUTING STRUCTURE
     *
     * The following routing is specific to users within the 'wayfinding' nav experience
     * and is structured to match up with the navigation menu layout in wayfinding-layout.vue.
     * Refer to my.clearbanc.com readme for details of creating new pages within wayfinding navigation system.
     */
    {
      path: '/account',
      component: AccountPage,
      beforeEnter: authGuard,
      redirect: { name: 'account-settings' },
      meta: {
        requiresAuth: true,
        segment: [
          'core',
          'self_serve',
          'extend_runway',
          'mrr_factoring',
          'indiegogo',
          'bnpl',
        ],
      },
      children: [
        {
          path: 'business-details', // MCA Route
          name: 'business-details',
          component: ApplicationComponent,
          beforeEnter: authGuard,
          props: (route) => ({
            section: route.name,
          }),
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'document-uploads', // MCA Route
          name: 'document-uploads',
          component: ApplicationComponent,
          beforeEnter: authGuard,
          props: (route) => ({
            section: route.name,
          }),
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'settings',
          name: 'account-settings',
          component: SettingsSubpage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'data-security',
          name: 'data-security',
          component: DataSecurityPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'data-security/remove',
          name: 'remove-data',
          component: RemoveDataPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'data-security/export',
          name: 'export-data',
          component: ExportDataPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
      ],
    },
    {
      path: '/financing', // MCA Route
      name: 'financing',
      component: FinancingHomePage,
      beforeEnter: authGuard,
      meta: {
        requiresAuth: true,
        displayNav: true,
      },
      children: [
        {
          path: 'spend', // MCA Route
          name: 'spend',
          component: SpendPage,
          beforeEnter: authGuard,
          meta: {
            requiresAuth: true,
            displayNav: true,
          },
        },
        {
          path: 'advances', // MCA Route
          name: 'advances',
          component: AdvancesPage,
          beforeEnter: authGuard,
          meta: {
            requiresAuth: true,
            displayNav: true,
          },
        },
      ],
    },
    {
      path: '/clearco-card', // MCA Route - Should Remove?
      name: 'clearco-card',
      component: ClearbancCardSubpage,
      beforeEnter: authGuard,
      meta: {
        displayNav: true,
      },
    },
    {
      path: '/vendor-list', // MCA Route
      name: PAYMENTS_ROUTE_NAMES.VENDOR_LIST,
      component: VendorListPage,
      beforeEnter: authGuard,
      meta: {
        displayNav: true,
      },
    },
    {
      path: '/pay-vendors',
      name: PAYMENTS_ROUTE_NAMES.PAY_VENDORS,
      component: VendorsPage,
      beforeEnter: async (to, from) => {
        authGuard(to);
        await auth0.getAccessTokenSilently();
        const userId = auth0?.user?.value?.userId;
        await fetchUserData(store, userId);
        if (
          store.getters.firstIncompletePaymentsOnboardingStep.name !==
          PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE
        ) {
          return {
            name: store.getters.firstIncompletePaymentsOnboardingStep.name,
          };
        }
        return true;
      },
      meta: {
        requiresAuth: true,
        displayNav: true,
      },
      children: [
        {
          path: 'business-profile',
          name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
          component: PaymentsBizProfileDashboard,
          beforeEnter: authGuard,
          children: [
            {
              path: 'connected-accounts',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_VERIFY_ACCOUNT,
              component: PaymentBizProfileConnectAccounts,
              beforeEnter: authGuard,
            },
            {
              path: 'connect-marketing',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_MARKETING,
              component: PaymentBizProfileConnectMarketing,
              beforeEnter: authGuard,
            },
            {
              path: 'banking',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING,
              component: PaymentBizProfileConnectBanking,
              beforeEnter(to, from, next) {
                authGuard(to);
                if (
                  store.getters.isPersonaEnabled &&
                  store.getters.businessProfileSteps[
                    PAYMENTS_ROUTE_NAMES.PROFILE_CONNECT_BANKING
                  ].isDisabled
                ) {
                  return next({ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD });
                }
                return next();
              },
            },
            {
              path: 'banking/:id/statements',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_STATEMENTS,
              component: PaymentBizProfileBankStatements,
              beforeEnter(to, from, next) {
                authGuard(to);
                return next();
              },
            },
            {
              path: 'documents',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BANK_DOCUMENTS,
              component: PaymentBizProfileBankDocuments,
              beforeEnter(to, from, next) {
                authGuard(to);
                return next();
              },
            },
            {
              path: 'debt-obligations',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_DEBT_OBLIGATIONS,
              component: PaymentBizProfileDebtObligations,
              beforeEnter(to, from, next) {
                authGuard(to);
                return next();
              },
            },
            {
              path: 'details',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS,
              component: PaymentBizProfileBusinessDetails,
              async beforeEnter(to, from, next) {
                authGuard(to);
                if (store.getters.isPersonaEnabled) {
                  return next({
                    name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS_PERSONA,
                  });
                }

                return next();
              },
            },
            {
              path: 'details-persona',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS_PERSONA,
              component: PaymentBizProfileBusinessDetailsPersona,
              beforeEnter: authGuard,
            },
            {
              path: 'address',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_REGISTERED_ADDRESS,
              component: PaymentBizProfileBusinessRegisteredAddress,
              beforeEnter(to, from, next) {
                authGuard(to);
                if (
                  store.getters.isPersonaEnabled &&
                  store.getters.businessProfileSteps[
                    PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS
                  ].isDisabled
                ) {
                  return next({ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD });
                }
                return next();
              },
            },
            {
              path: 'incorporation-details',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_INCORPORATION_DETAILS,
              component: PaymentsProfileBusinessIncoroporationDocs,
              beforeEnter(to, from, next) {
                authGuard(to);
                if (
                  store.getters.isPersonaEnabled &&
                  store.getters.businessProfileSteps[
                    PAYMENTS_ROUTE_NAMES.PROFILE_BUSINESS_DETAILS
                  ].isDisabled
                ) {
                  return next({ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD });
                }
                return next();
              },
            },
            {
              path: 'owners',
              name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS,
              component: PaymentBizProfileBusinessOwners,
              beforeEnter(to, from, next) {
                authGuard(to);
                if (
                  store.getters.isPersonaEnabled &&
                  store.getters.businessProfileSteps[
                    PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS
                  ].isDisabled
                ) {
                  return next({ name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD });
                }
                return next();
              },
              children: [
                {
                  path: ':id/step-one',
                  name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_ONE,
                  component: BusinessProfileOwnersStepOne,
                  beforeEnter: authGuard,
                  props: () => ({
                    theme: 'purple',
                  }),
                },
                {
                  path: ':id/step-two',
                  name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_STEP_TWO,
                  component: BusinessProfileOwnersStepTwo,
                  beforeEnter: authGuard,
                  props: () => ({
                    theme: 'purple',
                  }),
                },
                {
                  path: 'list',
                  name: PAYMENTS_ROUTE_NAMES.PROFILE_OWNERS_LIST,
                  component: BusinessProfileOwnersList,
                  beforeEnter: authGuard,
                  props: () => ({
                    theme: 'purple',
                    ctaLangKey: 'common.buttons.finish',
                  }),
                },
              ],
            },
            {
              path: 'persona-owners',
              name: PAYMENTS_ROUTE_NAMES.PERSONA_PROFILE_OWNERS,
              component: PersonaBizProfilePersonaBusinessOwners,
              beforeEnter: authGuard,
            },
          ],
        },
        {
          path: 'payments',
          name: PAYMENTS_ROUTE_NAMES.REPAYMENTS,
          component: VendorRepaymentsSubPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
          props: () => ({
            repaymentsType: 'history',
          }),
        },
        {
          path: 'upcoming-payments',
          name: PAYMENTS_ROUTE_NAMES.UPCOMING_REPAYMENTS,
          component: VendorRepaymentsSubPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
          props: () => ({
            repaymentsType: 'upcoming',
          }),
        },
        {
          path: 'payments/:billId?',
          name: PAYMENTS_ROUTE_NAMES.PAYMENT_TABLE,
          component: VendorPaymentsSubPage,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
        {
          path: 'all-documents',
          name: PAYMENTS_ROUTE_NAMES.ALL_DOCUMENTS,
          component: PaymentsAllDocuments,
          beforeEnter: authGuard,
          meta: {
            displayNav: true,
          },
        },
      ],
    },
    // Onoarding Flow
    {
      path: '/payments/onboarding',
      name: PAYMENTS_ROUTE_NAMES.ROOT,
      component: PaymentsOnboardingFlow,
      beforeEnter: authGuard,
      children: [
        {
          path: 'user-profile',
          name: PAYMENTS_ROUTE_NAMES.USER_PROFILE,
          component: PaymentsUserProfilePage,
          beforeEnter: authGuard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'business-details',
          name: PAYMENTS_ROUTE_NAMES.BUSINESS_DETAILS,
          component: PaymentsBusinessDetailsPage,
          beforeEnter: authGuard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: 'registered-address',
          name: PAYMENTS_ROUTE_NAMES.ADDRESS,
          component: PaymentsRegisteredAddressPage,
          beforeEnter: authGuard,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: '/not-found',
      name: 'not-found',
      alias: '/:pathMatch(.*)*',
      component: NotFoundPage,
      meta: {
        displayNav: true,
      },
      beforeEnter(to, from, next) {
        if (store.getters.userIsLoggedIn) {
          return next();
        }
        return next({ name: 'home' });
      },
    },
    {
      path: '/how-it-works',
      name: 'how-it-works',
      component: HowItWorks,
      beforeEnter: authGuard,
      meta: {
        displayNav: true,
      },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (
    Object.keys(to.query).some((param) =>
      PAYMENTS_TRACKING_PARAMS.includes(param),
    )
  ) {
    localStorage.setItem('signupParams', JSON.stringify(to.query));

    if (to.query.amplitude_id) {
      localStorage.setItem('amplitude_id', to.query.amplitude_id);
    }
  }
  try {
    if (!store.getters.globalFeatureFlags) {
      await store.dispatchApiAction('FETCH_GLOBAL_FEATURE_FLAGS');
      if (!store.getters.globalFeatureFlags) {
        await store.commit('DEFAULT_GLOBAL_FEATURE_FLAGS');
      }
    }
    if (store.getters.isMaintenancePageEnabled && to.name !== 'maintenance') {
      return next('/maintenance');
    }
    return next();
  } catch (error) {
    return next();
  }
});

router.beforeResolve(async (to, from, next) => {
  // This call is purely for local developement.
  // It's used to sync your local db with the auth-service dev db and Auth0 dev tenant
  if (
    process.env.CLEARBANC_ENV === ENVIRONMENTS.DEVELOPMENT &&
    auth0.isAuthenticated.value &&
    process.env.PROXY_TO_STAGING === 'false' &&
    to.name === 'home'
  ) {
    let shouldCreateUser = true;
    if (store.getters.isEmailVerificationEnabled) {
      shouldCreateUser = auth0?.user?.value.email_verified ?? false;
    }
    if (shouldCreateUser) {
      await bausRequest.post('/users/local-sync');
    }
  }

  function getAttributionParams(route, paramsToSave) {
    const params = new URLSearchParams(route.fullPath.split('?')[1]);
    const filteredParams = {};

    for (const key of params.keys()) {
      if (paramsToSave.includes(key)) {
        filteredParams[key] = params.get(key);
      }
    }

    return filteredParams;
  }

  const filteredToParams = getAttributionParams(to, PAYMENTS_TRACKING_PARAMS);
  const filteredFromParams = getAttributionParams(
    from,
    PAYMENTS_TRACKING_PARAMS,
  );

  let paramsToSend = {};
  const toHasParams = Object.keys(filteredToParams).length > 0;
  const fromHasParams = Object.keys(filteredFromParams).length > 0;

  if (toHasParams) {
    paramsToSend = filteredToParams;
  } else if (fromHasParams) {
    paramsToSend = filteredFromParams;
  }

  if (Object.keys(paramsToSend).length) {
    if (store.getters.isEmailVerificationEnabled) {
      // Sends UTM data to Auth0 via BAUS
      await store.dispatchApiAction('SEND_UTM_DATA', {
        urlParams: paramsToSend,
        authType: auth0?.user?.value?.loginsCount === 1 ? 'signup' : 'login',
      });
      router.replace({ query: {} });
    } else {
      // Stores UTM data in DB
      await store.dispatchApiAction('STORE_USER_ATTRIBUTION', {
        urlParams: paramsToSend,
        userId: auth0?.user?.value?.userId,
        authType: auth0?.user?.value?.loginsCount === 1 ? 'signup' : 'login',
      });
    }
  }

  if (
    ['logout', 'auth-error', 'login', 'signup', 'maintenance'].includes(to.name)
  )
    return next();

  const userId = auth0?.user?.value?.userId;
  if (!userId) {
    return next();
  }
  if (userId && Number(userId.value ?? userId) !== store.getters.userId) {
    const user = await store.dispatchApiAction('GET_USER', { userId });
    // TODO (sc-178331) Remove this condition when the write user in BAUS is done
    if (!user.business && user.businessId) {
      await store.dispatchApiAction('GET_BUSINESS');
      await store.dispatchApiAction('GET_OWNERS');
    }
  }

  const { featureFlagsToFetch } = store.getters;
  if (featureFlagsToFetch.length) {
    await store.dispatchApiAction('FETCH_USER_FEATURE_FLAGS');
  }

  // Remove stored UTM params from URL
  const hasParamsToRemove = PAYMENTS_TRACKING_PARAMS.some(
    (param) => to.query[param],
  );
  if (hasParamsToRemove) {
    const modifiedTo = {
      ...to,
      query: { ...to.query },
    };

    PAYMENTS_TRACKING_PARAMS.forEach((param) => {
      delete modifiedTo.query[param];
    });

    return next(modifiedTo);
  }

  return next();
});

router.afterEach((to, from) => {
  // eslint-disable-line no-unused-vars
  const pageProps = {};
  if (to.redirectedFrom) pageProps.redirected_from = to.redirectedFrom;

  localStorage.setItem('previous-route', from.path);

  // we must do this on nextTick so we get the actual URL
  setTimeout(() => {
    pageProps.productSegment = store.getters.productSegmentLabel;
    pageProps.fullPath = to.fullPath;
    pageProps.path = to.path;

    const isPageRefreshed = from.path === to.path;
    if (!isPageRefreshed) {
      analytics.page(to.name, pageProps);
    }
  });
});
export default router;
