<template>
  <Modal
    v-model:visible="showModal"
    header="Rename Account"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <div :class="$style['modal-content']">
      <VeeForm ref="form" :validation-schema="schema" @submit="submitForm">
        <InputTextGroup
          v-model="newBankAccountName"
          name="newBankAccountName"
          label="New Name"
        />
      </VeeForm>
    </div>

    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="Cancel"
          class="p-button-outlined"
          :class="$style.footer__button"
          @click="closeModal"
        />
        <DSButton
          label="Save"
          type="submit"
          :class="$style.footer__button"
          @click="submitForm"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { Form as VeeForm } from 'vee-validate';
import { string, object } from 'yup';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';

export default {
  emits: ['close'],
  components: {
    Modal,
    DSButton,
    InputTextGroup,
    VeeForm,
  },
  props: {
    selectedAccount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      newBankAccountName: '',
    };
  },
  computed: {
    schema() {
      const fieldSchema = {
        newBankAccountName: string()
          .required(this.$t('common.required'))
          .matches(
            /^[a-zA-Z0-9 ]+$/,
            'Only letters, numbers, and spaces are allowed',
          )
          .max(30, 'Name must be less than 30 characters'),
      };

      return object(fieldSchema);
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async submitForm() {
      const validationResponse = await this.$refs.form.validate();

      if (validationResponse.valid) {
        this.$emit('close');
        this.closeModal();

        await this.$store.dispatchApiAction('UPDATE_BANK_ACCOUNT', {
          id: this.selectedAccount.id,
          label: this.newBankAccountName,
        });
      }
    },
  },
};
</script>

<style lang="less" module>
body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

body .footer button.footer__button + button.footer__button {
  margin-left: 16px;
}
</style>
