import { ContactSupportMethods } from '@/data/contact-support';
import i18n from '@/plugins/i18n';

export const CONTACT_SUPPORT_MSG = (contactNumber) =>
  i18n.global.t('data.errorMessages.contactSupportMsg', {
    contactSupportMethodsPhoneDisplay: contactNumber,
    contactSupportMethodsEmailDisplay: ContactSupportMethods.email.display,
  });

export const CONTACT_SUPPORT_SUFFIX = i18n.global.t(
  'data.errorMessages.contactSupportSuffix',
  {
    contactSupportMethodsEmailDisplay: ContactSupportMethods.email.display,
  },
);

export const DISPLAYABLE_ERROR_CODES = {
  // '{lockedProp}' is currently locked and cannot be modified
  USER001: {
    support: true,
  },
  // 'Contract signing is limited to owners. Ensure you have the necessary ownership privileges to proceed.
  USER011: {},
  // There was an error uploading your PDF. Please try again
  HERON001: {},
  // It was not possible to upload your PDF. Please contact support at support@clear.co
  HERON002: {
    support: true,
  },
  // File too large to heron service
  HERON003: {},
  H_PDF_001: {
    i18nKey: 'backendError.H_PDF_001', // No valid Heron PDFs found for the business.
    static: "Please upload last month's statement.",
  },
  H_PDF_002: {
    i18nKey: 'backendError.H_PDF_002', // The last statement date exceeds {statementEndLimitInDays} days.
  },
  H_PDF_003: {
    i18nKey: 'backendError.H_PDF_003', // There was an error uploading your PDF. Please try again
  },
  H_PDF_004: {
    i18nKey: 'backendError.H_PDF_004', // The end date of the last statement ({statementEndDate}) does not match the expected end date: {pdfEndDate}.
  },
  H_PDF_005: {
    i18nKey: 'backendError.H_PDF_005', // Statements don't cover the initial date: {date}
  },
  H_PDF_006: {
    i18nKey: 'backendError.H_PDF_006', // Data for {reference} is missing from {startDate} to {endDate}
  },
};

// Errors below appends "Please contact us at support@clear.co" to the message
export const shouldDisplayContactSupport = (errorCode) => {
  return errorCode && DISPLAYABLE_ERROR_CODES[errorCode]?.support;
};

export const getDisplayedErrorMessage = (error, withContactSupportSuffix) => {
  const { errorCode, message } = error;
  const displayableError = DISPLAYABLE_ERROR_CODES[errorCode];
  if (displayableError) {
    const { static: staticMessage, i18nKey } = displayableError;
    if (staticMessage) return staticMessage;
    if (i18nKey) return i18nKey;
    if (message) {
      const messageWithPeriod = message.endsWith('.') ? message : `${message}.`;
      return withContactSupportSuffix && errorCode.support
        ? `${messageWithPeriod} ${CONTACT_SUPPORT_SUFFIX}`
        : messageWithPeriod;
    }
  }
  return undefined;
};
