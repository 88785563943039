<template>
  <div class="container">
    <svg
      v-if="!isPrimary"
      class="close-icon"
      @click="emitRemove"
      v-html="Close"
    ></svg>
    <div class="header">
      <div class="title">
        <span class="name">
          {{ `${owner.firstName} ${owner.lastName}` }}
        </span>
        <Tag
          v-if="owner.personaFormStatus"
          :severity="statuses[owner.personaFormStatus].severity"
        >
          {{ statuses[owner.personaFormStatus].text }}
        </Tag>
        <Tag v-else :severity="info">Pending</Tag>
      </div>
      <div v-if="isSignatory" class="signatory">
        <svg class="signatory-icon" v-html="Pen"></svg>Authorized Signatory
      </div>
    </div>
    <div class="row">
      <span class="label">Email</span>
      <span class="value">{{ owner.email }}</span>
    </div>
    <div class="row">
      <span class="label">Job title</span>
      <span class="value">{{ owner.jobTitle }}</span>
    </div>
    <div class="row">
      <span class="label">Owner type</span>
      <div v-if="isOwner || isDirector" class="value">
        <div v-if="isOwner">Beneficial Owner (>25% ownership)</div>
        <div v-if="isDirector">Director/Board Member</div>
      </div>
      <span v-else class="value">-</span>
    </div>
    <div class="separator"></div>
    <div class="actions">
      <a
        v-if="
          !isPrimary &&
          [PERSONA_STATUS.CREATED, PERSONA_STATUS.EXPIRED].includes(
            owner.personaFormStatus,
          )
        "
        class="actions__link"
        @click="resendVerificationEmail(owner)"
        >Send new link to ID verification</a
      >
      <a class="actions__link" @click="emitEdit">Edit</a>
    </div>
  </div>
</template>
<script>
import { OWNER_ROLES } from '@clearbanc/data-common-types';
import { PERSONA_STATUS } from '@/data/persona';
import { mapGetters } from 'vuex';
import Close from '@/assets/icons/close-black.svg?inline';
import Pen from '@/assets/icons/pen.svg?inline';
import Tag from '@clearbanc/clear-components/tag';

export default {
  components: { Tag },
  props: {
    owner: {
      required: true,
    },
  },
  data() {
    return {
      Close,
      Pen,
      PERSONA_STATUS,
      statuses: {
        created: { severity: 'warning', text: 'ID Verification Required' },
        completed: { severity: 'success', text: 'ID Verification Complete' },
        failed: { severity: 'success', text: 'ID Verification Complete' },
        pending: { severity: 'warning', text: 'ID Verification Required' },
        expired: { severity: 'danger', text: 'ID Verification Expired' },
      },
    };
  },
  computed: {
    ...mapGetters(['user']),
    isPrimary() {
      return this.owner.id === this.user.id;
    },
    isSignatory() {
      return this.owner.jobRoles.includes(OWNER_ROLES.SIGNATORY);
    },
    isDirector() {
      return this.owner.jobRoles.includes(OWNER_ROLES.DIRECTOR);
    },
    isOwner() {
      return this.owner.jobRoles.includes(OWNER_ROLES.OWNER);
    },
  },
  methods: {
    emitEdit() {
      this.$emit('edit-click');
    },
    emitRemove() {
      this.$emit('remove-click');
    },
    async resendVerificationEmail(ownerDetails) {
      await this.$store.dispatchApiAction('SEND_PERSONA_INQUIRY_EMAIL', {
        entityId: ownerDetails.id,
        entityType: 'owner',
        firstName: ownerDetails.firstName,
        lastName: ownerDetails.lastName,
        email: ownerDetails.email,
        shouldSendEmail: true,
        isEmailUpdated: false,
      });

      this.$toast.add({
        severity: 'success',
        detail: `ID verification re-sent to ${this.owner.email}.`,
        life: 4000,
      });
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  background: @color-white;
  border: 1px solid #ceced0;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  position: relative;
  border-radius: 4px;
  padding: 3rem;
  width: 100%;
  @media screen and (max-width: 640px) {
    padding: 3rem 1.5rem;
  }
  .close-icon {
    height: 12px;
    width: 12px;
    position: absolute;
    cursor: pointer;
    margin: 1rem;
    top: 0;
    right: 0;
  }
  .header {
    padding-bottom: 3rem;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      .name {
        font-weight: 500;
        font-size: 18px;
      }
    }
    .signatory {
      text-align: left;
      padding-top: 0.75rem;
      display: flex;
      align-items: center;
      .signatory-icon {
        height: 17px;
        width: 16px;
        margin-right: 0.5rem;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: column;
    padding-bottom: 3rem;
    align-items: flex-start;
    .label {
      font-weight: 500;
      font-size: 14px;
      color: #5f5f61;
      line-height: 35px;
    }
    .value {
      font-weight: 300;
      font-size: 16px;
      text-align: left;
      word-break: break-all;
    }
  }
  .separator {
    border: 0.5px solid #ceced0;
    margin-bottom: 1.5rem;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
    .actions__link {
      display: block;
      cursor: pointer;
      user-select: none;

      & + .actions__link {
        margin-left: auto;
      }
    }
  }
}
</style>
