import axios from 'axios';
import _ from 'lodash';

export default {
  state: {
    accountExecPhoto: {},
  },
  getters: {
    prismicAccountExecPhoto: (state) => state.accountExecPhoto,
  },
  actions: {
    GET_ACCOUNT_EXEC_PHOTO: async ({ commit }, uid) => {
      try {
        const configResponse = await axios.get(process.env.PRISMIC_BASE_URL);
        if (configResponse.status === 200) {
          const { refs } = configResponse.data;
          const masterRef = _.find(refs, { isMasterRef: true });
          const aeResponse = await axios.get(
            `${process.env.PRISMIC_BASE_URL}/documents/search?ref=${masterRef.ref}&q=[[at(my.account_manager.uid,%22${uid}%22)]]`,
          );
          if (aeResponse.status === 200) {
            const { results } = aeResponse.data;
            if (results.length) {
              commit('SET_ACCOUNT_EXEC_PHOTO', results[0].data);
            }
          }
        }
      } catch {
        // silently fail the call
      }
    },
  },
  mutations: {
    SET_ACCOUNT_EXEC_PHOTO: (state, aePhoto) => {
      state.accountExecPhoto = aePhoto;
    },
  },
};
