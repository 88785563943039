<template>
  <Modal
    v-model:visible="showModal"
    header="Select a primary account"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <div :class="$style['modal-content']">
      <p>
        All future debits will withdraw from this primary account. <br />
        You will be required to sign a new ACH for payment authorization.
      </p>
      <div :class="$style.accounts">
        <BankAccountItem
          v-for="account in visibleBankAccounts"
          :class="$style.accounts__item"
          :image="plaidInstitutions[account.bankName]?.logo"
          :bank-name="account.bankName"
          :account-name="account.label"
          :account-number="account.accountNumberLast4"
          :clickable="true"
          :selected="selectedPrimary?.id === account?.id"
          @click="changeSelectedPrimary(account)"
        />
      </div>
    </div>

    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="Cancel"
          class="p-button-outlined"
          :class="$style.footer__button"
          @click="closeModal"
        />
        <DSButton
          :disabled="!selectedPrimary"
          label="Confirm"
          :class="$style.footer__button"
          @click="confirmPrimary"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import BankAccountItem from '@/components/BankAccountItem';
import bankAccountUtils from '@/composables/bank-accounts';
import { useContextRoot } from '@/utils/context-root';

export default {
  components: {
    Modal,
    DSButton,
    BankAccountItem,
  },
  props: {
    selectedAccount: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { markBankAccountToBecomePrimary } = bankAccountUtils(
      useContextRoot(),
    );
    return {
      markBankAccountToBecomePrimary,
    };
  },
  data() {
    return {
      showModal: false,
      selectedPrimary: null,
    };
  },
  computed: {
    ...mapGetters(['visibleBankAccounts', 'plaidInstitutions']),
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    async confirmPrimary() {
      await this.markBankAccountToBecomePrimary(this.selectedPrimary.id);
      await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');

      this.showModal = false;
    },
    changeSelectedPrimary(account) {
      this.selectedPrimary = account;
    },
  },
};
</script>

<style lang="less" module>
body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

body .footer button.footer__button + button.footer__button {
  margin-left: 16px;
}

.modal-content p {
  margin: 0;
}

.accounts {
  margin-top: 30px;
  max-width: 665px;
}

.accounts__item {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e8e8ea;
  padding: 16px;
  width: 100%;
  max-width: 344px;
}

.accounts__item + .accounts__item {
  margin-top: 16px;
}
</style>
