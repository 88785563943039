<template>
  <div>
    <UiMsg
      v-if="
        updateBusinessRequest.isError ||
        updateUserRequest.isError ||
        createBusinessRequest.isError ||
        emailErrorMsg
      "
      type="error"
    >
      {{ computedErrorMsg }}
    </UiMsg>
    <Box :class="$style.box">
      <VeeForm
        class="c-form"
        :class="$style.form"
        :initial-values="initialValues"
        :validation-schema="schema"
        @submit="onSubmit"
      >
        <div class="c-form__row">
          <InputTextGroup
            name="firstName"
            :label="$t('common.firstName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'firstName');
                trackEvent('first_name_capture');
              }
            "
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="lastName"
            :label="$t('common.lastName')"
            :disabled="isPrimary && !!advanceContractUserSigned"
            @keydown="validateName"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'lastName');
              }
            "
          />
        </div>
        <div class="c-form__row">
          <InputTextGroup
            name="email"
            label="Email"
            :disabled="isPrimary"
            @blur="
              (e) => {
                updateOwnerParams(e.target.value, 'email');
              }
            "
          />
        </div>
        <div class="c-form__controls">
          <DSButton
            ref="submit"
            label="Continue"
            type="submit"
            :class="$style.button"
          />
        </div>
      </VeeForm>
    </Box>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import { isMobileMixin } from '@/utils/vue-mixins';
import { validateChildren } from '@/composables/validation';
import analytics from '@/utils/analytics';
import { CONTACT_SUPPORT_MSG } from '@/data/error-messages';
import UiMsg from '@/components/UiMsg';
import Box from '@/components/Box';
import { ENVIRONMENTS } from '@/data/environments';

import { Form as VeeForm } from 'vee-validate';
import { object, string } from 'yup';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';

export default {
  components: {
    UiMsg,
    Box,
    VeeForm,
    InputTextGroup,
    DSButton,
  },
  mixins: [isMobileMixin],
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    acceptedFileTypes: {
      type: String,
      default: 'image/jpeg,image/png,application/pdf',
    },
  },
  setup() {
    const { hasError } = validateChildren();
    return {
      hasError,
    };
  },
  data() {
    return {
      idValidationErrorMsg: this.$t(
        'components.formOwnersStepOne.idValidationErrorMsg',
      ),
      schema: object({
        firstName: string().nullable().required(this.$t('common.required')),
        lastName: string().nullable().required(this.$t('common.required')),
        email: string().nullable().required(this.$t('common.required')).email(),
      }),
      emailErrorMsg: null,
    };
  },
  computed: {
    ...mapGetters([
      'selectedOwner',
      'user',
      'business',
      'advanceInNegotiation',
      'advanceContracts',
      'business',
      'productSegmentLabel',
      'contactDetails',
      'isDiligenceServiceReadDataEnabled',
      'businessOwners',
      'allOwners',
      'isBausIntegrationEnabled',
    ]),
    ...mapRequestStatuses({
      updateBusinessRequest: ['UPDATE_NON_PRIMARY_USER'],
      createBusinessRequest: ['CREATE_NON_PRIMARY_USER'],
      updateUserRequest: 'UPDATE_USER_PROFILE',
    }),
    computedErrorMsg() {
      return (
        this.emailErrorMsg || CONTACT_SUPPORT_MSG(this.contactDetails.number)
      );
    },
    isPrimary() {
      return !!this.selectedOwner?.isPrimary;
    },
    corpCountry() {
      return this.business.corpCountry;
    },
    advanceContractUserSigned() {
      return this.advanceContracts[this.advanceInNegotiation.id]?.userSignedAt;
    },
    routerParams() {
      return this.$route.params;
    },
    initialValues() {
      return {
        firstName: this.selectedOwner?.firstName ?? '',
        lastName: this.selectedOwner?.lastName ?? '',
        email: this.selectedOwner?.email ?? '',
      };
    },
  },
  watch: {
    async routerParams(newValue) {
      if (newValue.id && !this.selectedOwner) {
        await this.$store.dispatch(
          'SET_SELECTED_OWNER',
          this.$route.params?.id,
        );
      }
    },
  },
  async beforeMount() {
    if (!this.selectedOwner && this.$route.params?.id) {
      await this.$store.dispatch('SET_SELECTED_OWNER', this.$route.params.id);
    }
  },
  methods: {
    validateName(event) {
      const nameRegex = /^([a-z.']+\s?)+$/i;
      if (!nameRegex.test(event.key)) {
        event.preventDefault();
      }
    },
    updateOwnerParams(val, name) {
      this.$store.dispatch('UPDATE_OWNER_PARAMS', { [name]: val });
    },
    trackEvent(name, props) {
      analytics.track(`fe_${name}`, { ...this.eventTrackingFields, ...props });
    },
    checkForEmailError() {
      const missingEmail = !this.selectedOwner?.email;

      let businessHasOwnerWithSameEmail = false;

      if (!missingEmail) {
        businessHasOwnerWithSameEmail = this.allOwners.some(
          (owner) =>
            owner.email === this.selectedOwner.email &&
            owner.id !== this.selectedOwner.id,
        );
      }

      this.emailErrorMsg = businessHasOwnerWithSameEmail
        ? 'This email is already in use.'
        : '';

      return missingEmail || businessHasOwnerWithSameEmail;
    },
    async saveUser(owner) {
      const { firstName, lastName } = owner;
      await this.$store.dispatchApiAction('UPDATE_USER_PROFILE', {
        firstName,
        lastName,
      });
      if (!this.updateUserRequest.isSuccess) {
        const errorMessage = this.updateUserRequest.error.errorCode
          ? this.$t(
              `backendError.${this.updateUserRequest.error.errorCode}`,
              this.updateUserRequest.error.details,
            )
          : this.updateUserRequest.errorMessage;
        this.$emit('validationError', errorMessage); // check if this is going to a segment event
        return;
      }
      this.$emit('validationSuccess');
    },
    async saveBusiness(owner) {
      const existingOwner = this.businessOwners.some(
        (businessOwner) => businessOwner.id === owner.id,
      );

      const updatedOwnerData = {
        firstName: owner.firstName,
        lastName: owner.lastName,
        email: owner.email,
        ...((existingOwner || !this.isBausIntegrationEnabled) && {
          id: owner.id,
        }),
      };

      let error;
      if (!existingOwner) {
        const newUser = await this.$store.dispatchApiAction(
          'CREATE_NON_PRIMARY_USER',
          updatedOwnerData,
        );

        error = this.createBusinessRequest.error;
        if (!error) {
          // SC-177347 remove this condition when we rollout multi user
          // const createdId = this.isBausIntegrationEnabled ? newUser.id : newId;
          const createdId = this.isBausIntegrationEnabled
            ? newUser.id
            : newUser.owners?.find(({ email }) => email === owner.email)?.id;
          await this.$store.dispatch('SET_SELECTED_OWNER', createdId);
        }
      } else {
        await this.$store.dispatchApiAction(
          'UPDATE_NON_PRIMARY_USER',
          updatedOwnerData,
        );
        error = this.updateBusinessRequest.error;
      }

      if (error) {
        const errorMessage = error.errorCode
          ? this.$t(`backendError.${error.errorCode}`, error.details)
          : error.message;
        this.$emit('validationError', errorMessage);
        return;
      }
      this.$emit('validationSuccess');
    },
    async onSubmit(values) {
      // skip check in unit tests
      if (process.env.CLEARBANC_ENV !== ENVIRONMENTS.TEST) {
        if (await this.hasError()) {
          return;
        }
      }
      window.scrollTo(0, 0);
      if (!this.checkForEmailError()) {
        this.isPrimary
          ? await this.saveUser(this.selectedOwner)
          : await this.saveBusiness(this.selectedOwner);
      }
    },
  },
};
</script>

<style lang="less" module>
.form {
  width: 330px;
}

.button {
  min-width: 186px;
}
</style>
