// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body [class^=p-dialog] [class^=p-dialog-content]{padding:0;margin:2rem}.description_NlyFj{max-width:500px;margin:0}.banks_lf9bi{margin-top:30px}.banks__item_WQb6O+.banks__item_WQb6O{margin-top:24px}.banks__item--disabled_zUwnq{opacity:.5}body .footer_Jg6E5 button.footer__button_owhBY{margin:0;width:calc(50% - 8px)}body .footer_Jg6E5 button.footer__button_owhBY+button.footer__button_owhBY{margin-left:16px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": "description_NlyFj",
	"banks": "banks_lf9bi",
	"banks__item": "banks__item_WQb6O",
	"banks__item--disabled": "banks__item--disabled_zUwnq",
	"footer": "footer_Jg6E5",
	"footer__button": "footer__button_owhBY"
};
export default ___CSS_LOADER_EXPORT___;
