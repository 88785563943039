// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bank_ZHyPp{position:relative;border:1px solid #5b00f0;text-align:left;padding:16px;display:flex;align-items:center;color:#303032;font-size:14px;line-height:1.4}.bank--disabled_PrMan{border-color:#e8e8ea}.bank--clickable_UEDhF{cursor:pointer;border:1px solid #e8e8ea}.bank--selected_wOsaW{border-color:#5b00f0}.bank__tag_yd6Ly{position:absolute;top:-16px;left:4px;padding:6px 8px;background:#000;color:#fff;border-radius:4px;font-size:12px;font-weight:500;line-height:1}.bank__bullet_gKDy0{width:20px;height:20px;border:1px solid #000;border-radius:50%;margin-right:16px}.bank--selected_wOsaW .bank__bullet_gKDy0{border-width:4px;border-color:#5b00f0}.bank__logo_lODyh{margin-right:8px}.bank__details__account-name_LlQlI{font-weight:700}.bank__details__bank-name_GiweZ{display:inline-block;margin-right:2px}.bank__details__account-number_a4cwQ{display:inline-block;margin-left:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bank": "bank_ZHyPp",
	"bank--disabled": "bank--disabled_PrMan",
	"bank--clickable": "bank--clickable_UEDhF",
	"bank--selected": "bank--selected_wOsaW",
	"bank__tag": "bank__tag_yd6Ly",
	"bank__bullet": "bank__bullet_gKDy0",
	"bank__logo": "bank__logo_lODyh",
	"bank__details__account-name": "bank__details__account-name_LlQlI",
	"bank__details__bank-name": "bank__details__bank-name_GiweZ",
	"bank__details__account-number": "bank__details__account-number_a4cwQ"
};
export default ___CSS_LOADER_EXPORT___;
