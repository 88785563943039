import { ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  PERSONA_TEMPLATE_NAMES,
  PERSONA_TEMPLATES,
  PERSONA_STATUS,
  PERSONA_ENTITY,
  PERSONA_ENV,
  PERSONA_SCRIPT_ID,
} from '../data/persona';
import provincesByCountry from '../data/states-by-country';

function getStateShortCode(country, stateName) {
  return provincesByCountry[country]?.find(
    (state) => state.name() === stateName,
  )?.value;
}

function getCorpType(personaCorpType) {
  const types = {
    Corporation: 'corp',
    'Limited Liability Company': 'llc',
  };
  return types[personaCorpType] || '';
}

function getPersonaFieldData(template, fields) {
  const newFieldData = {};

  // Address exists on every type of verification
  if (fields['address-street-1'].value) {
    newFieldData.address = {
      line1: fields['address-street-1'].value,
      line2: fields['address-street-2'].value,
      postalCode: fields['address-postal-code'].value,
      city: fields['address-city'].value,
      state: fields['address-subdivision'].value,
      country:
        template === PERSONA_TEMPLATE_NAMES.BUSINESS
          ? fields['selected-country-code'].value
          : fields['selected-country-of-residence'].value,
    };
  }

  if (template === PERSONA_TEMPLATE_NAMES.BUSINESS) {
    if (fields['business-name'].value) {
      newFieldData.name = fields['business-name'].value;
    }
    if (fields['selected-country-code'].value) {
      newFieldData.corpCountry = fields['selected-country-code'].value;
    }
    if (
      fields['incorporation-province'].value ||
      fields['incorporation-state'].value
    ) {
      const stateFullValue =
        fields['incorporation-province'].value ||
        fields['incorporation-state'].value;
      const stateShortName = getStateShortCode(
        newFieldData.corpCountry,
        stateFullValue,
      );
      if (stateShortName) newFieldData.corpState = stateShortName;
    }
    if (fields['legal-business-structure'].value) {
      newFieldData.corpType = fields['legal-business-structure'].value;
    }
    if (fields['business-tax-identification-number'].value) {
      newFieldData.federalTaxId = fields[
        'business-tax-identification-number'
      ].value.replace('-', '');
    }
    if (fields['business-incorporation-date'].value) {
      newFieldData.corpDate = fields['business-incorporation-date'].value;
    }
    if (fields['legal-business-structure'].value) {
      const normalizedCorpType = getCorpType(
        fields['legal-business-structure'].value,
      );
      if (normalizedCorpType) newFieldData.corpType = normalizedCorpType;
    }
  }

  if (template === PERSONA_TEMPLATE_NAMES.INDIVIDUAL) {
    if (fields.birthdate.value) {
      newFieldData.birthday = fields.birthdate.value;
    }
    if (fields['name-last'].value) {
      newFieldData.lastName = fields['name-last'].value;
    }
    if (fields['name-first'].value) {
      newFieldData.firstName = fields['name-first'].value;
    }
    if (fields['phone-number'].value) {
      newFieldData.phoneNumber = fields['phone-number'].value;
    }
    if (fields['selected-country-code'].value) {
      newFieldData.citizenship = fields['selected-country-code'].value;
    }
    if (fields['personal-identification-number'].value) {
      newFieldData.personalTaxId = fields[
        'personal-identification-number'
      ].value.replace(/-/g, '');
    }
    // The user consents inside persona modal
    newFieldData.agreedToKyc = true;
  }
  return newFieldData;
}

export default function usePersonaVerification() {
  const store = useStore();
  if (!store.getters.isPersonaEnabled) return {};

  let personaClient;
  let entityId;
  let entityType;
  let templateName;

  const isReady = ref(false);
  const completeStatus = ref();
  const personaData = ref();
  const inquiryId = ref();
  const personaError = ref();
  const queueModalOpen = ref();
  const successCb = ref();

  async function addPersonaScript() {
    return new Promise((resolve, reject) => {
      if (
        !document.head
          .getElementsByTagName('script')
          .namedItem(PERSONA_SCRIPT_ID)
      ) {
        const script = document.createElement('script');
        script.onload = resolve;
        script.onerror = (event) => {
          personaError.value = {
            code: 500,
            status: `Failed to load ${event?.target?.src}`,
          };
        };
        script.setAttribute('id', PERSONA_SCRIPT_ID);
        script.setAttribute(
          'src',
          'https://cdn.withpersona.com/dist/persona-v4.7.1.js',
        );
        document.head.appendChild(script);
      }
    });
  }

  function getDefaultEntityId(template) {
    return template === PERSONA_TEMPLATE_NAMES.BUSINESS
      ? store.getters.business.id
      : store.getters.user.id;
  }

  function getDefaultEntityType(template) {
    return template === PERSONA_TEMPLATE_NAMES.BUSINESS
      ? PERSONA_ENTITY.BUSINESS
      : PERSONA_ENTITY.USER;
  }

  function getFieldDefaults(template) {
    return template === PERSONA_TEMPLATE_NAMES.BUSINESS
      ? {
          businessName: store.getters.business.name,
          addressStreet1: store.getters.business.addressStreet1,
          addressStreet2: store.getters.business.addressStreet2,
          addressCity: store.getters.business.addressCity,
          addressSubdivision: store.getters.business.addressState,
          addressPostalCode: store.getters.business.addressPostalCode,
        }
      : {
          informalNameFirst: store.getters.user.firstName || '',
          nameFirst: store.getters.user.firstName,
          nameLast: store.getters.user.lastName,
          phoneNumber: store.getters.user.phoneNumber,
          emailAddress: store.getters.user.email,
          addressCountryCode: store.getters.user.addressCountryCode,
          addressStreet1: store.getters.user.address.line1,
          addressCity: store.getters.user.address.city,
          addressSubdivision: store.getters.user.address.state,
          addressPostalCode: store.getters.user.address.postalCode,
          KYBCaseId: store.getters.isPersonaCorporationVerificationComplete
            ? store.getters.personaCompletedCorporationVerification[0].caseId
            : '',
          sendEmail: false,
        };
  }

  function generateReferenceId(id, type) {
    if (!id || !type) return '';
    const prefix = type === PERSONA_ENTITY.BUSINESS ? 'B' : 'U';
    return `${prefix}-${id}`;
  }

  async function initPersonaClient({ template, id, type }) {
    if (!Object.keys(PERSONA_TEMPLATES).includes(template)) {
      throw new Error('Persona template not supported');
    }

    entityId = id || getDefaultEntityId(template);
    entityType = type || getDefaultEntityType(template);
    templateName = template;
    const referenceId = generateReferenceId(entityId, entityType);

    if (window && window.Persona) {
      personaClient = new window.Persona.Client({
        templateId: PERSONA_TEMPLATES[template],
        referenceId,
        environmentId:
          process.env.CLEARBANC_ENV === 'production'
            ? PERSONA_ENV.PRODUCTION
            : PERSONA_ENV.SANDBOX,
        onReady: () => {
          isReady.value = true;
        },
        onComplete: (data) => {
          completeStatus.value = data.status;
          personaData.value = data;
          inquiryId.value = data.inquiryId;
        },
        onCancel: (data) => {
          completeStatus.value = PERSONA_STATUS.CANCELED;
          personaData.value = data;
          inquiryId.value = data.inquiryId;
        },
        onError: (err) => {
          personaError.value = err;
          store.commit('SHOW_API_ERROR_POPUP', 'timeout');
        },
        fields: getFieldDefaults(template),
      });
    }
  }

  function openPersonaModal(cb) {
    if (cb && !successCb.value) {
      successCb.value = cb;
    }

    if (!isReady.value) {
      queueModalOpen.value = true;
      return;
    }
    personaClient.open();
    queueModalOpen.value = false;
  }

  watch(
    [isReady, inquiryId, successCb],
    async ([cIsReady, cInquiryId, cSuccessCb]) => {
      if (!cIsReady || !cInquiryId) {
        return;
      }

      const inquiry = await store.dispatchApiAction('GET_PERSONA_INQUIRY', {
        inquiryId: cInquiryId,
        entityId: String(entityId),
        entityType,
      });

      if (
        (templateName === PERSONA_TEMPLATE_NAMES.BUSINESS &&
          !store.getters.isPersonaCorporationVerificationComplete) ||
        (templateName !== PERSONA_TEMPLATE_NAMES.BUSINESS &&
          !store.getters.personaOwnerVerification(store.getters.user.id))
      ) {
        return;
      }

      const { fields } = inquiry.verificationResponse.data.attributes;
      const updatedFieldData = getPersonaFieldData(templateName, fields);

      if (cSuccessCb && typeof cSuccessCb === 'function') {
        await cSuccessCb(updatedFieldData);
      }
      if (templateName === PERSONA_TEMPLATE_NAMES.BUSINESS) {
        await store.dispatchApiAction('UPDATE_BUSINESS', updatedFieldData);
      }
    },
  );

  watch([isReady, queueModalOpen], async ([cIsReady, cQueueModalOpen]) => {
    if (!cIsReady || !cQueueModalOpen) return;
    if (cIsReady && cQueueModalOpen) {
      openPersonaModal();
    }
  });

  onMounted(async () => {
    if (!store.getters.isPersonaEnabled) return;
    await addPersonaScript();
  });

  return {
    initPersonaClient,
    openPersonaModal,
    openPersonaModalQueued: queueModalOpen,
    personaData,
    personaError,
    personaIsReady: isReady,
    personaCompleteStatus: completeStatus,
  };
}
