<template>
  <div>
    <form-row no-inputs="no-inputs">
      <i18n-t keypath="account.pages.subpageSecurity.yourEmailIs">
        <template #email>
          <b>{{ user.email }}</b>
        </template>
      </i18n-t>
    </form-row>
    <form-row no-inputs="no-inputs">
      <Checkbox
        v-model="agreedToNewsletter"
        binary
        input-id="agreedToNewsletter"
        :disabled="updateUserRequest.isPending"
        @change="handleMarketingEmailsChange"
      />
      <label for="agreedToNewsletter" class="m-l-10">
        Receive marketing emails
      </label>
    </form-row>
    <form-row>
      <v-button theme="transparent-dark" @click="showEmailPopup">
        {{ $t('common.changeEmail') }}
      </v-button>
      <v-button
        :theme="'transparent-dark'"
        :disabled="passwordRequestComplete"
        @click="initiateChangePassword"
      >
        Reset Password
      </v-button>
      <v-button theme="transparent-dark" @click="showLogoutPopup">
        {{ $t('common.signOut') }}
      </v-button>
    </form-row>
    <form-row v-if="passwordRequestComplete" no-inputs="no-inputs">
      <strong>{{ $t('account.pages.subpageSecurity.checkYourEmail') }}</strong>
    </form-row>
    <form-row v-if="passwordRequestError" no-inputs="no-inputs">
      <Message severity="error" icon="pi-shield" :closable="false">
        <p>
          An error occured while trying to reset your password. Please try again
          or contact customer support.
        </p>
      </Message>
    </form-row>
    <popup
      ref="changeEmailPopup"
      :title="$t('account.pages.subpageSecurity.changeYourEmail')"
    >
      <form-row>
        <form-input
          v-model="setEmail.email"
          type="text"
          :label="$t('common.emailAddress')"
          required="required"
        />
        <form-input
          v-model="setEmail.validatePassword"
          name="user[password]"
          autocomplete="on"
          type="password"
          :label="$t('common.currentPasswordLowerCase')"
          required="required"
        />
      </form-row>
      <form-row>
        <Message
          v-if="changeEmailError"
          severity="error"
          icon="pi-shield"
          :closable="false"
        >
          <p>
            An error occured while trying to change your email. Please try again
            or contact customer support.
          </p>
        </Message>

        <v-button
          :disabled="!!validationErrors.length"
          :loading="startChangeEmail"
          :loading-text="$t('common.saving')"
          @click="saveUpdatedEmail"
        >
          {{ $t('account.pages.subpageSecurity.saveNewEmail') }}
        </v-button>
      </form-row>
    </popup>
    <popup
      ref="logoutPopup"
      :title="$t('account.pages.subpageSecurity.signOutOfYourAccount')"
    >
      <form-row no-inputs="no-inputs">
        <p>
          <b>{{ $t('account.pages.subpageSecurity.areYouSure') }}</b>
        </p>
      </form-row>
      <form-row>
        <v-button :to="{ name: 'logout' }">
          {{ $t('account.pages.subpageSecurity.yesSignOut') }}
        </v-button>
      </form-row>
    </popup>
    <Toast position="top-center" group="tl" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Toast from '@clearbanc/clear-components/toast';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import axios from 'axios';
import analytics from '@/utils/analytics';
import { useMeta } from 'vue-meta';
import { validateChildren } from '@/composables/validation';
import Message from '@clearbanc/clear-components/message';
import { bausRequest } from '@/utils/baus-request';
import Checkbox from '@clearbanc/clear-components/checkbox';

export default {
  components: {
    Message,
    Checkbox,
    Toast,
  },
  setup() {
    useMeta({
      title: 'Account Settings | Clearco',
    });
    const { hasError, validationErrors } = validateChildren();

    return {
      hasError,
      validationErrors,
    };
  },
  data() {
    return {
      setPass: {},
      setEmail: {},
      passwordRequestComplete: false,
      passwordRequestError: false,
      changeEmailError: false,
      startChangeEmail: false,
      agreedToNewsletter: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'productSegmentLabel']),
    ...mapRequestStatuses({
      updateUserRequest: 'UPDATE_USER',
    }),
    getTargetArea() {
      return {
        targetArea: this.$route.name,
      };
    },
    eventCommonProps() {
      return {
        productSegment: this.productSegmentLabel,
        ...this.getTargetArea,
      };
    },
  },
  mounted() {
    this.setEmail.email = _.get(this.user, 'email');
    this.agreedToNewsletter = this.user.agreedToNewsletter;
  },
  methods: {
    trackWayfindingUserClickEvent(name) {
      analytics.track(`fe_${name}_clicked`, this.eventCommonProps);
    },
    showEmailPopup() {
      this.trackWayfindingUserClickEvent('change_email');
      this.setEmail = { email: this.user.email, password: '' };
      this.$refs.changeEmailPopup.open();
    },
    async initiateChangePassword() {
      this.passwordRequestError = false;
      this.trackWayfindingUserClickEvent('change_password');
      const data = {
        client_id: process.env.AUTH0_CLIENT_ID,
        email: this.user.email,
        connection: 'Username-Password-Authentication',
      };
      return axios
        .post(
          `https://${process.env.AUTH0_DOMAIN}/dbconnections/change_password`,
          data,
        )
        .then(() => {
          this.passwordRequestComplete = true;
        })
        .catch((error) => {
          this.passwordRequestError = true;
        });
    },
    showLogoutPopup() {
      this.trackWayfindingUserClickEvent('sign_out');
      this.$refs.logoutPopup.open();
    },
    async saveUpdatedEmail() {
      this.startChangeEmail = true;
      this.changeEmailError = false;
      const error = await this.hasError();
      if (error) return;

      await bausRequest
        .patch(`/users/${this.user.id}`, this.setEmail)
        .then((res) => {
          this.$store.commit('UPDATE_USER_PARAMS', { email: res.data.email });
        })
        .catch(() => {
          this.changeEmailErrorMsg = true;
        })
        .finally(() => {
          this.startChangeEmail = false;
          this.$refs.changeEmailPopup.close();
        });
    },
    async handleMarketingEmailsChange() {
      await this.$store.dispatchApiAction('UPDATE_USER', {
        agreedToNewsletter: this.agreedToNewsletter,
      });

      this.$toast.add({
        severity: 'success',
        detail: `Email marketing preferences updated.`,
        group: 'tl',
        life: 1500,
      });
    },
  },
};
</script>
