<template>
  <Modal
    v-model:visible="showModal"
    header="Remove Account"
    :modal="true"
    :draggable="false"
    append-to="self"
  >
    <div :class="$style['modal-content']">
      Thank you for submitting a bank account deletion request. A member of our
      team will be in touch to finalize the account removal.
    </div>

    <template #footer>
      <div :class="$style.footer">
        <DSButton
          label="Close"
          class="p-button-outlined"
          :class="$style.footer__button"
          @click="closeModal"
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '@clearbanc/clear-components/dialog';
import DSButton from '@clearbanc/clear-components/button';
import analytics from '@/utils/analytics';

export default {
  components: {
    Modal,
    DSButton,
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(['userId']),
  },
  methods: {
    openModal(account) {
      analytics.track('remove_bank_account', {
        bankAccountId: account.id,
        bankName: account.bankName,
        bankAccountLabel: account.label,
        userId: this.userId,
      });

      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="less" module>
body .footer button.footer__button {
  margin: 0;
  width: calc(50% - 8px);
}

.modal-content {
  max-width: 500px;
}
</style>
