<template>
  <div>
    <div data-cy="bank-connections-section">
      <div :class="$style.container">
        <img
          v-if="showPlaidPreview"
          :class="$style['plaid-img']"
          :src="plaidPreview"
          :alt="$t('components.containerConnectBankAccounts.plaidModalPreview')"
        />
        <div v-else :class="$style.spacer"></div>
      </div>
      <OverlayLoadingIcon v-if="loading" />
    </div>
    <BankIntegrations
      ref="bankIntegrations"
      @success="handlePlaidSuccess"
      @error="handleErrors"
      @exit="handleBankingConnectExit"
      @plaidOpened="trackPlaidModal('opened')"
      @flowEvent="trackPlaidFlowEvent"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapRequestStatuses } from '@/utils/vuex-api-utils';
import BankIntegrations from '@/components/BankIntegrations';
import OverlayLoadingIcon from '@/components/OverlayLoadingIcon';
import analytics from '@/utils/analytics';

export default {
  components: {
    BankIntegrations,
    OverlayLoadingIcon,
  },
  props: {
    eventTrackingFields: { type: Object, default: () => {} },
    showError: { type: Boolean, required: true },
  },
  data() {
    return {
      loading: false,
      showPlaidPreview: true,
    };
  },
  computed: {
    ...mapGetters(['productSegmentLabel', 'bankAccounts', 'business']),
    ...mapRequestStatuses({
      addBankConnectionRequest: 'ADD_USER_BANK_CONNECTION',
    }),
    plaidPreview: () => require('@/assets/images/plaid-preview.png'),
    currentRoute() {
      return this.$route.name;
    },
  },
  methods: {
    openBankingIntegration(routingNumber) {
      this.loading = true;
      this.$emit('update:showError', false);
      this.$refs.bankIntegrations.openBankIntegration(
        this.currentRoute,
        routingNumber && !(routingNumber instanceof PointerEvent)
          ? routingNumber
          : null,
      );
    },
    async handlePlaidSuccess() {
      await this.$store.dispatchApiAction('FETCH_USER_BANK_ACCOUNTS');
      this.clearBankingConnectErrors();
      this.$emit('plaidSuccess');
    },
    handleErrors(plaidMetadata) {
      if (!this.addBankConnectionRequest.isSuccess) {
        this.showPlaidPreview = false;
        if (plaidMetadata.error_code) {
          this.$store.commit(
            'CLEAR_REQUEST_STATUS',
            'ADD_USER_BANK_CONNECTION',
          );
        }
      }
      this.loading = false;
      this.$emit('plaidError', plaidMetadata);
    },
    clearBankingConnectErrors() {
      // Plaid will still emit error while user is retrying. This will clear the error state if the retry within the modal is successful
      this.loading = false;
      this.showPlaidPreview = true;
      this.$emit('update:showError', false);
    },
    handleBankingConnectExit() {
      this.loading = false;
      this.trackPlaidModal('quit');
      this.$emit('exit');
    },
    trackPlaidModal(action, relink) {
      analytics.track(`fe_plaid_${action}`, {
        ...this.eventTrackingFields,
        relink: !!relink,
      });
    },
    trackPlaidFlowEvent({ eventName, metadata }, relink) {
      // sometimes if the user exits no error gets emitted by plaid but it is captured in the metadata in a flow event
      if (metadata.error_code) {
        this.handleErrors(metadata);
      }
      const bankTrackingProps = {
        ...this.eventTrackingFields,
        bank: metadata.institution_name,
        institution_id: metadata.institution_id,
        relink: !!relink,
      };

      if (eventName === 'SELECT_INSTITUTION') {
        analytics.track('fe_select_bank', {
          ...bankTrackingProps,
        });
      } else if (eventName === 'SUBMIT_CREDENTIALS') {
        analytics.track('fe_bank_account_attempt', {
          ...bankTrackingProps,
          link_session_id: metadata.link_session_id,
        });
      }
    },
  },
};
</script>

<style>
.p-message-wrapper {
  text-align: left;
}

.p-message p {
  font-weight: 400 !important;
}

.p-message .p-message-close.p-link {
  transform: translate(0, 0) !important;
  margin: -0.8rem -1.2rem 0 auto !important;
}

.p-message .p-message-close {
  min-width: 1.75rem !important;
  min-height: 1.75rem !important;
}
</style>

<style lang="less" module>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.plaid-img {
  width: 100%;
  max-width: 260px;
  padding-top: 10px;
}
.spacer {
  height: 64px;
}
</style>
