<template>
  <div>
    <Message v-if="showSignatoryError" severity="error" :closable="false">
      <p>
        {{ signatoryErrorMsg }}
      </p>
    </Message>
    <Message
      v-if="expiredPersonaOwners.length"
      severity="warn"
      :closable="false"
    >
      <h3>Action Required</h3>
      <p>
        ID verification links for the following individuals have expired:
        {{ expiredPersonaNames }}. To complete this section, please click below
        to send new links and have the individual(s) complete their ID
        verification process.
      </p>
      <a
        @click="
          !apiRequestInProgress && sendNewPersonaEmailToAllExpiredOwners()
        "
        >Send new link(s) to ID verification</a
      >
    </Message>
    <div class="persona-owners">
      <div class="owners-container">
        <div
          v-for="(owner, index) in allOwners"
          :key="index"
          class="owner-card"
        >
          <FormPersonaOwners
            v-if="selectedOwner?.id === owner.id"
            :id="selectedOwner?.id"
            :owner="owner"
            @isSignatory="showSignatoryError = false"
            @cancelClick="handleCancel()"
            @removeClick="handleRemove(owner)"
          />
          <PersonaOwnersCard
            v-else
            :owner="owner"
            @editClick="handleEdit(owner.id)"
            @removeClick="handleRemove(owner)"
          />
        </div>
        <div v-if="addingNewOwner" class="owner-card">
          <FormPersonaOwners
            :owner="newOwnerId"
            @removeTempOwner="newOwnerId = null"
            @isSignatory="showSignatoryError = false"
            @removeClick="handleRemove"
          />
        </div>
      </div>
      <div class="buttons-container">
        <DSButton
          ref="addProfile"
          :disabled="someOwnerInEditMode"
          label="+ Add a profile"
          class="p-button-secondary"
          @click="addProfile"
        />
        <DSButton
          ref="done"
          :disabled="someOwnerInEditMode"
          label="Done"
          @click="done"
        />
      </div>
    </div>
    <ConfirmDialog class="text-center" :draggable="false" />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mapStateModuleGetters } from '@/utils/state-modules';
import FormPersonaOwners from '@/components/forms/FormPersonaOwners';
import PersonaOwnersCard from '@/components/payments/PersonaOwnersCard';
import DSButton from '@clearbanc/clear-components/button';
import Message from '@clearbanc/clear-components/message';
import { PAYMENTS_ROUTE_NAMES } from '@/data/payments';
import { OWNER_ROLES } from '@clearbanc/data-common-types';
import ConfirmDialog from '@clearbanc/clear-components/confirmdialog';
import analytics from '@/utils/analytics';

export default {
  components: {
    FormPersonaOwners,
    PersonaOwnersCard,
    DSButton,
    Message,
    ConfirmDialog,
  },
  computed: {
    ...mapGetters([
      'allOwners',
      'selectedOwner',
      'businessOwners',
      'business',
      'user',
      'productSegmentLabel',
      'personaOwnerVerification',
    ]),
    ...mapStateModuleGetters(['experimentName']),
    currentRoute() {
      return this.$route.name;
    },
    commonTrackingFields() {
      return {
        productSegment: this.productSegmentLabel,
        targetArea: this.currentRoute,
        experiment: this.experimentName,
      };
    },
    hasAtLeastOneSignatory() {
      const hasSignatory = this.allOwners.some((owner) => {
        return owner.jobRoles?.includes(OWNER_ROLES.SIGNATORY);
      });
      return hasSignatory;
    },
    someOwnerInEditMode() {
      return !!this.selectedOwner;
    },
    addingNewOwner() {
      return this.selectedOwner?.id === this.newOwnerId;
    },
    expiredPersonaOwners() {
      return this.allOwners.filter(
        (owner) => owner.personaFormStatus === 'expired',
      );
    },
    expiredPersonaNames() {
      const names = this.expiredPersonaOwners.map(
        (owner) => `${owner.firstName} ${owner.lastName}`,
      );

      return this.formatTextList(names);
    },
    expiredPersonaEmails() {
      const emails = this.expiredPersonaOwners.map((owner) => owner.email);
      return this.formatTextList(emails);
    },
  },
  data() {
    return {
      showSignatoryError: false,
      newOwnerId: null,
      signatoryErrorMsg:
        'You must identify an authorized signatory to continue, and they must hold one of the following titles: Founder/C-Suite, Vice President, Treasurer, Controller or Officer.',
      discardChangesModalOptions: {
        header: 'Unsaved Changes',
        message:
          'The information in this profile has unsaved changes. Do you want to continue?',
        acceptLabel: 'Discard changes',
        rejectLabel: 'Continue editing',
      },
      deleteProfileModalOptions: {
        header: 'Delete this profile?',
        message:
          'The information in this profile will be erased and cannot be undone. Do you want to continue?',
        acceptLabel: 'Delete profile',
        rejectLabel: 'Cancel',
      },
      doneConsentModalOptions: {
        header: 'Confirmation of Owner and Director Information',
        message:
          'You certify, to the best of your knowledge, that you have provided us with accurate and complete beneficial ownership and director information.',
        acceptLabel: 'Confirm',
        rejectLabel: 'Cancel',
      },
      apiRequestInProgress: false,
    };
  },
  beforeMount() {
    if (!this.user.jobTitle || !this.personaOwnerVerification(this.user.id)) {
      this.selectOwner(this.user.id);
    }
  },
  methods: {
    done() {
      this.showSignatoryError = !this.hasAtLeastOneSignatory;
      if (this.showSignatoryError) {
        this.displaySignatoryError();
      } else {
        this.handleDone();
      }
    },
    async addProfile() {
      // Adding the new owner in this newOwners temp array to not create a new owner
      // in the store directly, only if the user clicks on 'save' inside the form
      this.newOwnerId = 'new';
      await this.selectOwner(this.newOwnerId);
      analytics.track('fe_owner_summary_tile', this.commonTrackingFields);
    },
    async handleEdit(ownerId) {
      // checking if the user clicking on edit one owner while editing another
      if (this.selectedOwner && this.selectedOwner.id !== ownerId) {
        const accept = {
          accept: async () => {
            await this.selectOwner(ownerId);
            this.newOwnerId = null;
            this.scrollTo(this.selectedOwner.id, { block: 'center' });
          },
        };
        this.openDiscardChangesModal(accept);
      } else {
        await this.selectOwner(ownerId);
        analytics.track('fe_owner_confirmation_edit_click', {
          ...this.commonTrackingFields,
          email: this.selectedOwner.email,
        });
      }
    },
    async handleCancel() {
      const accept = {
        accept: async () => {
          await this.$store.dispatch('UNSELECT_OWNER');
        },
      };
      this.openDiscardChangesModal(accept);
    },
    async handleRemove(owner) {
      const modalOptions = {
        ...this.deleteProfileModalOptions,
        accept: async () => {
          if (this.newOwnerId && !owner) {
            this.newOwnerId = null;
          } else {
            await this.removeExistingOwner(owner);
          }
          await this.$store.dispatch('UNSELECT_OWNER');
        },
      };
      this.openConfirmModal(modalOptions);
    },
    async handleDone() {
      const modalOptions = {
        ...this.doneConsentModalOptions,
        accept: async () => {
          analytics.track('fe_self_serve_stage_complete', {
            businessId: this.business.id,
            userId: this.user.id,
            stage: this.currentRoute,
          });
          this.$router.push({
            name: PAYMENTS_ROUTE_NAMES.PROFILE_DASHBOARD,
          });
        },
      };
      this.openConfirmModal(modalOptions);
    },
    async removeExistingOwner(owner) {
      await this.$store.dispatchApiAction('DELETE_NON_PRIMARY_USER', owner);
      analytics.track('fe_delete_owner_details_click', {
        ...this.commonTrackingFields,
        email: owner.email,
      });
    },
    async selectOwner(ownerId) {
      this.selectedOwner = await this.$store.dispatch(
        'SET_SELECTED_OWNER',
        ownerId,
      );
    },
    displaySignatoryError() {
      this.scrollTo('businessProfileContainer');
      analytics.track('fe_error_banner_displayed', {
        ...this.commonTrackingFields,
        errorMsg: this.signatoryErrorMsg,
      });
    },
    openDiscardChangesModal(params) {
      const modalOptions = {
        ...this.discardChangesModalOptions,
        ...params,
      };
      this.openConfirmModal(modalOptions);
    },
    openConfirmModal(modalOptions) {
      this.$confirm.require({
        rejectClass: 'p-button-outlined',
        acceptClass: 'p-button-primary',
        defaultFocus: 'none',
        ...modalOptions,
      });
    },
    scrollTo(element, options) {
      document
        .getElementById(element)
        .scrollIntoView({ behavior: 'smooth', ...options });
    },
    formatTextList(list) {
      if (list.length > 1) {
        const lastEntry = list.pop();
        return `${list.join(', ')} & ${lastEntry}`;
      }

      return list[0];
    },
    async sendNewPersonaEmailToAllExpiredOwners() {
      this.apiRequestInProgress = true;
      const promises = [];

      this.expiredPersonaOwners.forEach((owner) => {
        promises.push(
          this.$store.dispatchApiAction('SEND_PERSONA_INQUIRY_EMAIL', {
            entityId: owner.id,
            entityType: 'owner',
            firstName: owner.firstName,
            lastName: owner.lastName,
            email: owner.email,
            shouldSendEmail: true,
            isEmailUpdated: false,
          }),
        );
      });

      await Promise.all(promises);

      this.$toast.add({
        severity: 'success',
        detail: `ID verifications re-sent to ${this.expiredPersonaEmails}.`,
        life: 4000,
      });

      await this.$store.dispatchApiAction('GET_PERSONA_VERIFICATIONS');
      this.apiRequestInProgress = false;
    },
  },
};
</script>

<style lang="less" scoped>
.persona-owners {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .owners-container {
    width: 430px;
    @media screen and (max-width: 640px) {
      width: 100%;
    }

    .owner-card {
      padding-bottom: 4rem;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    height: 13rem;

    @media screen and (max-width: 640px) {
      margin-bottom: 3.5rem;
    }

    .p-button {
      width: fit-content;
    }
  }
}

:deep(.p-message) {
  margin: 0;
}

:deep(.p-message-wrapper) {
  text-align: left;
  margin: 3rem 0 0 0;
  font-size: 14px;
  align-items: flex-start;

  h2 {
    font-weight: 500;
    margin: 0;

    & + p {
      margin-top: 8px;
    }
  }

  p {
    font-weight: 300;
    margin: 0;

    & + a {
      margin-top: 8px;
    }
  }

  a {
    display: inline-block;
    font-weight: normal;
    cursor: pointer;
  }
}
</style>
